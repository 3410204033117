<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="ftb_table_section" id="ftb_md_table" v-else-if="tables">
        <div class="current_league_score_table">
            <table class="league_score_table">
                <thead>
                <tr>
                    <th>
                        {{ $t("football.Place") }}
                    </th>
                    <th>
                        {{ $t("Teams") }}
                    </th>
                    <th>
                        GP
                    </th>
                    <th>
                        {{ $t("football.Won") }}
                    </th>
                    <th class="details_player_hide_mobile">
                        {{ $t("football.Draw") }}
                    </th>
                    <th class="details_player_hide_mobile">
                        {{ $t("football.Lost") }}
                    </th>
                    <th class="details_player_hide_mobile">
                        GF
                    </th>
                    <th class="details_player_hide_mobile">
                        GA
                    </th>
                    <th class="details_player_hide_mobile">
                        GD
                    </th>
                    <th>
                        {{ $t("Points") }}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr style="background: #fff" v-for="table in tables">
                    <td>
                        <span class="highlight-border"></span><!-- [style.background-color]="color"-->
                        {{table.place}}
                    </td>
                    <td style="background: #fff">
                      <div class="current_league_score_table_teamlogo">
                          <img :onerror="$teamDefaultLogo()" :src="$storageURL+'images/rugby/'+table.team_id+'_90x90.webp'" alt="team logo">
                          {{table.team}}
                      </div>
                    </td>
                    <td style="background: #fff">
                        {{table.played}}
                    </td>
                    <td style="background: #fff">
                        {{table.won}}
                    </td>
                    <td class="details_player_hide_mobile" style="background: #fff">
                        {{table.draws}}
                    </td>
                    <td class="details_player_hide_mobile" style="background: #fff">
                        {{table.lost}}
                    </td>
                    <td class="details_player_hide_mobile" style="background: #fff">
                        {{table.scored}}
                    </td>
                    <td class="details_player_hide_mobile" style="background: #fff">
                        {{table.conceded}}
                    </td>
                    <td class="details_player_hide_mobile" style="background: #fff">
                        {{table.difference}}
                    </td>
                    <td style="background: #fff">
                        {{table.points}}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'MatchDetailTable',
        data() {
            return {
                tables: null,
            }
        },
        mounted() {
            this.getTableData();
        },
        serverPrefetch() {
            return this.getTableData();

        },
        metaInfo() {
            let ss = this.$store.state.siteSetting;
            var replacements = {
                '%TOURNAMENT_NAME%': this.$store.state.rugbyMatchDetailResponse.data.tournament.name,
            };

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.rugby_match_table_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.rugby_match_table_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.rugby_match_table_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link:[
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        methods: {
            getTableData() {
                this.is_data_ready = false;
                return this.$axios
                    .get(this.$RUGBY_MATCH_DETAIL_TABLE_API.replace(':match', this.$route.params.matchId))
                    .then(response => {
                        this.tables = response.data.table;
                        this.is_data_ready = true;

                    })
                    .catch(error => console.log(error))
            },
        }
    }

</script>
